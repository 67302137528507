import React from "react";
import "loaders.css/loaders.min.css";
import cx from "classnames";

function Loader({
  fullScreen = true,
  type = "default",
}: {
  fullScreen?: boolean;
  type?: "default" | "transparent";
}) {
  const isTransparent = type === "transparent";

  return (
    <div
      className={cx("flex items-center justify-center", {
        "h-screen": fullScreen,
        "fixed inset-0  backdrop-filter backdrop-blur-lg z-20": isTransparent,
      })}
    >
      <div className="ball-clip-rotate">
        <div style={{ borderColor: "black", borderBottomColor: "white" }} />
      </div>
    </div>
  );
}

export default Loader;
