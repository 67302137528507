import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Howl } from "howler";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { TEN_SECONDS } from "utils";
import { api } from "utils/api";
import { Me } from "types/user";
import useLocalStorage from "hooks/useLocalStorage";

const useExpertSessionPolling = () => {
  const queryClient = useQueryClient();

  const [notifications, setNotifications] = useLocalStorage(
    "notifications",
    {}
  );
  const [sessionPreferences] = useLocalStorage("session_preferences", {});

  const history = useHistory();
  const { data: me } = useQuery<Me>(["me"], () =>
    api.get("/users/v1/users/me")
  );
  const { data } = useQuery(
    ["expert-nearest-sessions"],
    () => api.get(`/users/v1/my-nearest-sessions`),
    {
      refetchInterval: TEN_SECONDS,
      refetchIntervalInBackground: true,
      onSuccess: () => {
        queryClient.invalidateQueries(["future-sessions"], {
          refetchType: "all",
          refetchPage() {
            return true;
          },
        });
      },
    }
  );

  const [activeSessions, setActiveSessions] = useState<any>();
  const [hasActiveSession, setHasActiveSession] = useState(false);

  useEffect(() => {
    if (data) {
      const sessions = data?.sessions.filter((s) => {
        const isHidden = sessionPreferences?.[s.id]?.isNotificationHidden;

        return s.user.id !== me?.id && !isHidden;
      });
      setActiveSessions(sessions);
      setHasActiveSession(sessions?.length >= 1);
    }
  }, [data?.sessions?.length]);

  const showActiveSession = history
    ? history?.location?.pathname.search(/prepare|active/g) === -1
    : true;

  useEffect(() => {
    if (hasActiveSession && showActiveSession) {
      const session = activeSessions[0];
      if (!notifications[session.id]) {
        const sound = new Howl({
          src: "/waylightnotification.mp3",
          volume: 0.3,
        });
        sound.play();
        setNotifications({ [session.id]: true });
      }
    }
  }, [activeSessions, showActiveSession]);

  return {
    activeSessions,
    activeSingleSessions: showActiveSession ? data?.singleSessions ?? [] : [],
    hasActiveSession: showActiveSession && hasActiveSession,
  };
};

export default useExpertSessionPolling;
