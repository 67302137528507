import { useMutation, useQuery } from "@tanstack/react-query";
import { Me } from "types/user";
import { api } from "utils/api";

export function useUser() {
  const user = useQuery<Me>(["me"], () => api.get("/users/v1/users/me"), {
    retry: false,
  });

  const { mutateAsync: signOut } = useMutation(() =>
    api.delete("/users/v1/auth/signout")
  );
  return { ...user, signOut };
}
