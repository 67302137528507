import { Fragment, useState, useEffect } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { LogoutIcon } from "@heroicons/react/outline";
import {
  ChevronDownIcon,
  CurrencyDollarIcon,
  XIcon,
  DownloadIcon,
  TicketIcon,
  QrcodeIcon,
  ShoppingBagIcon,
  VideoCameraIcon,
  CalendarIcon,
  ThumbUpIcon,
  CreditCardIcon,
  UserCircleIcon,
  CollectionIcon,
  SparklesIcon,
  UsersIcon,
  QuestionMarkCircleIcon,
  MenuIcon,
} from "@heroicons/react/outline";
import Link from "next/link";
import { ILoggedUser } from "pages";
import { INearestSession } from "types/sessions";
import useExpertSessionPolling from "hooks/useExpertSessionPolling";
import useClientSessionPolling from "hooks/useClientSessionPolling";
// import { getFullName } from "utils";
import { LANGUAGES } from "hooks/useLanguage";
import Banner from "components/Banner/Banner";
import { useUser } from "hooks/useUser";
import { useRouter } from "next/router";
import CookieBanner from "components/CookieBanner/CookieBanner";
import SupportModal from "components/SupportModal/SupportModal";
import { Link as ScrollLink } from "react-scroll";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { api } from "utils/api";

export default function PublicNav({
  loggedUser,
  imagesLoaded,
  language,
}: {
  loggedUser: ILoggedUser;
  imagesLoaded: boolean;
  language?: string;
}) {
  const router = useRouter();
  const expertSession = useExpertSessionPolling();
  const clientSession = useClientSessionPolling();

  const [isMainOpen, setIsMainOpen] = useState(true);
  const [isProductOpen, setIsProductOpen] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(true);
  const [isSupportModalOpen, setisSupportModalOpen] = useState(false);
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  const mutation = useMutation((payload: { language: string }) =>
    api.patch("/users/v1/users", payload)
  );

  const changeLanguageNext = (newLanguage) => {
    i18n.changeLanguage(newLanguage);
    mutation.mutate({ language: newLanguage.toLocaleLowerCase() });
  };

  useEffect(() => {
    if (language) {
      changeLanguageNext(language);
    }
  }, [language]);

  const handleProductClick = () => {
    setIsMainOpen(!isMainOpen);
    setIsProductOpen(!isProductOpen);
  };

  const handleSupportClick = () => {
    setMenuOpen(false);
    setisSupportModalOpen(true);
  };

  const appUrl = loggedUser?.isExpert
    ? "/app/expert/dashboard"
    : "/app/purchased";

  const { signOut } = useUser();

  const handleSignOut = async () => {
    await signOut();
    router.reload();
  };

  return (
    <>
      {expertSession.activeSessions?.length > 0 &&
        expertSession.activeSessions.map((session: INearestSession) => {
          const sessionPath = `/app/expert/sessions/${session.id}`;

          if (window.location.pathname === sessionPath) {
            return null;
          }

          return (
            <Banner
              key={session.id}
              onClick={() => (window.location.href = sessionPath)}
              avatar={session.expert.avatar}
              userAvatar={session?.user?.avatar}
            />
          );
        })}

      {clientSession.activeSessions?.length > 0 &&
        clientSession.activeSessions.map((session: INearestSession) => {
          const sessionPath = `/app/sessions/${session.id}`;

          if (window.location.pathname === sessionPath) {
            return null;
          }

          return (
            <Banner
              key={session.id}
              onClick={() => (window.location.href = sessionPath)}
              avatar={session.expert.avatar}
              userAvatar={session?.user?.avatar}
            />
          );
        })}

      {expertSession.activeSingleSessions.length > 0
        ? expertSession.activeSingleSessions.map(
            (session: { id: string; date: string; expert: any; user: any }) => (
              <Banner
                key={session.id}
                backgroundColor="bg-pink-600"
                iconColor="bg-pink-700"
                onClick={() => window.location.assign(`/session/${session.id}`)}
                avatar={session.expert.avatar}
                userAvatar={session?.user?.avatar}
              />
            )
          )
        : null}
      <CookieBanner />
      <Disclosure as="nav" className="bg-slate-900 w-full relative top-0 z-30">
        {() => (
          <>
            <div className="flex max-w-7xl mx-auto px-4 h-14 sm:h-16">
              <div className="relative flex items-center justify-between">
                <div className="flex items-center px-2 lg:px-0">
                  <div className="shrink-0">
                    <Link
                      href="/"
                      className="flex items-center flex-shrink-0 sm:hidden"
                    >
                      <img
                        width={40}
                        src="/img/logo/waylight-logo.svg"
                        alt="Waylight logo"
                        className="object-cover object-center"
                      />
                    </Link>
                    <Link
                      href="/"
                      className="hidden sm:flex sm:items-center flex-shrink-0"
                    >
                      <img
                        width={101}
                        height={29}
                        src="/img/logo/waylight-standart-logo.svg"
                        alt="Waylight logo"
                        className="h-7"
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="flex flex-1 items-center lg:ml-6 justify-end">
                <div className="flex items-center">
                  <a className="py-1" href={appUrl}>
                    <button className="flex items-center gap-2 mx-3 text-white max-w-[160px]">
                      <div className="h-9 w-9 bg-slate-50 rounded-full">
                        <img
                          alt="image avatar"
                          src={
                            loggedUser?.isExpert
                              ? loggedUser?.expertAvatar
                              : loggedUser?.avatar
                          }
                          className="h-9 w-9  rounded-full bg-avatar-gradient"
                        />
                      </div>
                    </button>
                  </a>

                  <Menu
                    as="div"
                    className={
                      !isMenuOpen ? "opacity-0" : "opacity-100 relative ml-4"
                    }
                  >
                    {({ open }) => {
                      if (!open) {
                        setMenuOpen(true);
                      }
                      return (
                        <>
                          <Menu.Button
                            className="flex items-center text-sm focus:outline-none sm:border-l-2 sm:border-r-2
                       sm:border-dashboard-gray sm:px-4"
                          >
                            <MenuIcon className="w-6 h-6 text-white" />
                          </Menu.Button>
                          {isMainOpen && (
                            <Transition
                              show={open}
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items
                                static
                                className="origin-top-right absolute right-8 mt-3 w-36 rounded-xl rounded-tr-none shadow-lg py-2 bg-white overflow-hidden ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
                              >
                                <Menu.Item>
                                  <div>
                                    <ScrollLink
                                      to="pricing"
                                      offset={!imagesLoaded && 650}
                                      smooth={true}
                                      duration={500}
                                      target="_blank"
                                      className="flex items-center gap-2 w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100 cursor-pointer"
                                    >
                                      <CurrencyDollarIcon className="w-5 h-5 text-gray-500" />
                                      {t("PricingMenuItem")}
                                    </ScrollLink>
                                  </div>
                                </Menu.Item>
                                <Menu.Item>
                                  <Link href="" passHref>
                                    <div
                                      className="flex items-center gap-2 w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100 cursor-pointer"
                                      onClick={handleProductClick}
                                    >
                                      <SparklesIcon className="w-5 h-5 text-gray-500" />
                                      {t("productMenuItem")}
                                    </div>
                                  </Link>
                                </Menu.Item>
                                <Menu.Item>
                                  <Link href="" passHref>
                                    <div
                                      onClick={handleSupportClick}
                                      className="ml-1 flex items-center gap-2 py-2.5 px-3 rounded-md text-sm text-gray-700 cursor-pointer hover:bg-gray-100"
                                    >
                                      <QuestionMarkCircleIcon className="w-6 h-6 text-gray-500" />
                                      <span>{t("support")}</span>
                                    </div>
                                  </Link>
                                </Menu.Item>
                                <Menu.Item>
                                  <button
                                    type="button"
                                    className="flex items-center gap-2 w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100 cursor-pointer"
                                    onClick={handleSignOut}
                                  >
                                    <LogoutIcon className="w-5 h-5 text-slate-500 shrink-0" />
                                    <span className="truncate">
                                      {t("signOut")}
                                    </span>
                                  </button>
                                </Menu.Item>
                              </Menu.Items>
                            </Transition>
                          )}
                        </>
                      );
                    }}
                  </Menu>

                  <Menu as="div" className="order-1 relative ml-4 z-10">
                    {({ open }) => (
                      <>
                        <Menu.Button className="flex items-center gap-2 py-1 text-white focus:outline-none focus:ring-2 focus:ring-white">
                          {i18n.language.toUpperCase()}
                          <ChevronDownIcon className="w-4 h-4" />
                        </Menu.Button>
                        <Transition
                          show={open}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items
                            static
                            className="origin-top-right absolute right-0 mt-3 w-48 rounded-md shadow-lg py-1 bg-white overflow-hidden ring-1 ring-black ring-opacity-5 focus:outline-none "
                          >
                            {LANGUAGES.map((lang) => (
                              <Menu.Item key={lang.id}>
                                <button
                                  className="block w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100 cursor-pointer"
                                  onClick={() =>
                                    changeLanguageNext(lang.code.toLowerCase())
                                  }
                                >
                                  {lang.name}
                                </button>
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </>
                    )}
                  </Menu>
                </div>
              </div>
            </div>
          </>
        )}
      </Disclosure>
      {isSupportModalOpen && (
        <SupportModal
          setisSupportModalOpen={setisSupportModalOpen}
          type="landingMobile"
        />
      )}
      {isProductOpen && (
        <div
          onMouseLeave={handleProductClick}
          className={`fixed top-0 bottom-0 overflow-y-auto xl:bottom-auto xl:top-auto xl:absolute z-50  xl:w-11/12 xl:max-w-screen-2xl xl:mx-auto left-0 right-0 xl:overflow-hidden xl:rounded-b-xl`}
        >
          <button
            className="absolute cursor-pointer top-0 right-0 m-4  xl:hidden"
            onClick={handleProductClick}
          >
            <XIcon className="w-6 h-6 text-black" />
          </button>
          <div className="bg-white w-full h-full overflow-auto">
            <div className="p-5 ">
              <h1 className="text-4xl xl:hidden font-extrabold mt-8 mb-8">
                {t("product")}
              </h1>
              <ul className="text-slate-900 xl:grid xl:grid-cols-4">
                <div className="xl:w-[400px]">
                  <li className="flex mb-6 md:mb-4 lg:mb-8 text-sm font-medium">
                    <div className="bg-black shrink-0 basis-auto mr-3 text-white h-5 w-5 flex items-center justify-center rounded-t-full rounded-l-full">
                      <img
                        alt="telegram"
                        src="/img/telegram-product.svg"
                        className="w-3 h-3"
                      />
                    </div>
                    {t("paidSubscriptionCommunitiesTelegram")}
                  </li>

                  <li className="flex mb-6 lg:mb-8 text-sm font-medium">
                    <div className="bg-black shrink-0 basis-auto mr-3 text-white h-5 w-5 flex items-center justify-center rounded-t-full rounded-l-full">
                      {" "}
                      <img
                        alt="telegram"
                        src="/img/telegram-product.svg"
                        className="w-3 h-3"
                      />
                    </div>
                    {t("paidGroupChannelAccessTelegram")}
                  </li>

                  <li className="flex mb-6 lg:mb-8 text-sm font-medium">
                    <div className="bg-black shrink-0 basis-auto mr-3 text-white h-5 w-5 flex items-center justify-center rounded-t-full rounded-l-full">
                      <DownloadIcon className="w-3 h-3" />
                    </div>
                    {t("paidAccessDigitalProducts")}
                  </li>
                  <li className="flex mb-6 lg:mb-8 text-sm font-medium">
                    <div className="bg-black shrink-0 basis-auto mr-3 text-white h-5 w-5 flex items-center justify-center rounded-t-full rounded-l-full">
                      <DownloadIcon className="w-3 h-3" />
                    </div>
                    {t("paidAccessCourses")}
                  </li>
                </div>
                <div className="xl:w-[250px] xl:ml-28">
                  <li className="flex mb-6 text-sm font-medium">
                    <div className="bg-black shrink-0 basis-auto mr-3 text-white h-5 w-5 flex items-center justify-center rounded-t-full rounded-l-full">
                      <TicketIcon className="w-3 h-3" />
                    </div>
                    {t("ticketsPersonEvents")}
                  </li>
                  <li className="flex mb-6 lg:mb-8 text-sm font-medium">
                    <div className="bg-black shrink-0 basis-auto mr-3 text-white h-5 w-[18.29px] flex items-center justify-center rounded-t-full rounded-l-full">
                      <QrcodeIcon className="w-3 h-3" />
                    </div>
                    {t("ticketsScanner")}
                  </li>
                  <li className="flex mb-6 lg:mb-8 text-sm font-medium">
                    <div className="bg-black shrink-0 basis-auto mr-3 text-white h-5 w-[18.29px] flex items-center justify-center rounded-t-full rounded-l-full">
                      <TicketIcon className="w-3 h-3" />
                    </div>
                    {t("ticketsOnlineEvents")}
                  </li>
                  <li className="flex mb-6 lg:mb-8 text-sm font-medium">
                    <div className="bg-black shrink-0 basis-auto mr-3 text-white h-5 w-[18.29px] flex items-center justify-center rounded-t-full rounded-l-full">
                      <ShoppingBagIcon className="w-3 h-3" />
                    </div>
                    {t("paidServices")}
                  </li>
                </div>
                <div className="xl:w-[250px] xl:ml-16">
                  <li className="flex mb-6 lg:mb-8 text-sm font-medium">
                    <div className="bg-black shrink-0 basis-auto mr-3 text-white h-5 w-[18.29px] flex items-center justify-center rounded-t-full rounded-l-full">
                      <VideoCameraIcon className="w-3 h-3" />
                    </div>
                    {t("paidVideoSessions")}
                  </li>
                  <li className="flex mb-6 lg:mb-8 text-sm font-medium">
                    <div className="bg-black shrink-0 basis-auto mr-3 text-white h-5 w-[18.29px] flex items-center justify-center rounded-t-full rounded-l-full">
                      <CalendarIcon className="w-3 h-3" />
                    </div>
                    {t("paidAppointments")}
                  </li>
                  <li className="flex mb-6 lg:mb-8 text-sm font-medium">
                    <div className="bg-black shrink-0 basis-auto mr-3 text-white h-5 w-[18.29px] flex items-center justify-center rounded-t-full rounded-l-full">
                      <ThumbUpIcon className="w-3 h-3" />
                    </div>
                    {t("tipping")}
                  </li>
                  <li className="flex mb-6 lg:mb-8 text-sm font-medium">
                    <div className="bg-black shrink-0 basis-auto mr-3 text-white h-5 w-[18.29px] flex items-center justify-center rounded-t-full rounded-l-full">
                      <CreditCardIcon className="w-3 h-3" />
                    </div>
                    {t("securePayments")}
                  </li>
                </div>
                <div className="xl:w-[280px] xl:ml-8">
                  <li className="flex mb-6 lg:mb-8 text-sm font-medium">
                    <div className="bg-black shrink-0 basis-auto mr-3 text-white h-5 w-[18.29px] flex items-center justify-center rounded-t-full rounded-l-full">
                      <UserCircleIcon className="w-3 h-3" />
                    </div>
                    {t("businessWebsite")}
                  </li>
                  <li className="flex mb-6 lg:mb-8 text-sm font-medium">
                    <div className="bg-black shrink-0 basis-auto mr-3 text-white h-5 w-[18.29px] flex items-center justify-center rounded-t-full rounded-l-full">
                      <CollectionIcon className="w-3 h-3" />
                    </div>
                    {t("portfolioProduct")}
                  </li>
                  <li className="flex mb-6 lg:mb-8 text-sm font-medium">
                    <div className="bg-black shrink-0 basis-auto mr-3 text-white h-5 w-[18.29px] flex items-center justify-center rounded-t-full rounded-l-full">
                      <SparklesIcon className="w-3 h-3" />
                    </div>
                    {t("reviewsProduct")}
                  </li>
                  <li className="flex text-sm font-medium">
                    <div className="bg-black shrink-0 basis-auto mr-3 text-white h-5 w-[18.29px] flex items-center justify-center rounded-t-full rounded-l-full">
                      <UsersIcon className="w-3 h-3" />
                    </div>
                    {t("customersDataManagement")}
                  </li>
                </div>
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
