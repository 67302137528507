import isFunction from "lodash/isFunction";
import React, { useEffect, useRef, useState } from "react";
import { configureRange } from "./slider-utils";
import useBrowser from "hooks/useBrowser";

interface Props {
  id?: string;
  name?: string;
  tooltip?: boolean;
  className?: string;
  children?: any;
  value: number;
  defaultValue?: number;
  disabled?: boolean;
  onChange?: any;
  onChangeComplete?: any;
  range: any;
}

export default function StepRangeSlider(props: Props) {
  const [value, setValue] = useState(props.value);
  const [range, setRange] = useState<any>(props.range);
  const [currentStep, setCurrentStep] = useState(0);
  const [pressed, setPressed] = useState(false);
  const [sliderRect, setSliderRect] = useState<any>({});
  const [event, setEvent] = useState<any>({});

  const { isMobile } = useBrowser();
  const slider: any = useRef();

  const scrollArea = isMobile
    ? slider.current?.parentElement
    : slider.current?.parentElement?.parentElement;

  useEffect(() => {
    if (pressed) {
      scrollArea?.addEventListener("touchmove", handleTouchMove);
      scrollArea?.addEventListener("touchend", handleMouseUp);
      scrollArea?.addEventListener("mousemove", handleMouseMove);
      scrollArea?.addEventListener("mouseup", handleMouseUp);
    }
  }, [event]);

  useEffect(() => {
    // return a function to execute at unmount
    return () => {
      scrollArea?.removeEventListener("touchmove", handleTouchMove);
      scrollArea?.removeEventListener("touchend", handleMouseUp);
      scrollArea?.removeEventListener("mousemove", handleMouseMove);
      scrollArea?.removeEventListener("mouseup", handleMouseUp);
    };
  }, [event]); // notice the empty array

  useEffect(() => {
    setInitialState(props);
  }, []);

  useEffect(() => {
    if (range) {
      setInitialState(props);
    }
    if (value) {
      const newValue = range.ensureValue(value);
      setValue(newValue);
    }
  }, []);

  useEffect(() => {
    handleChange();
  }, [value]);

  const setInitialState = (props) => {
    const configureValue = configureRange(props.range);
    const ensureValue = configureValue.ensureValue(0);
    const currentStep = configureValue.getStepForValue(ensureValue);
    setValue(ensureValue);
    setRange(configureValue);
    setCurrentStep(currentStep);
  };

  const handleChange = () => {
    const { onChange } = props;
    isFunction(onChange) && onChange(value);
  };

  const handleChangeComplete = () => {
    const { onChangeComplete } = props;
    isFunction(onChangeComplete) && onChangeComplete(value);
  };

  const handleMouseUp = (e) => {
    if (pressed) {
      setPressed(false);
      handleChangeComplete();
    }
  };

  const handleMouseMove = (e) => {
    if (pressed) {
      setEvent(e);
    }
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    handlePress();
    setEvent(e);
  };

  const handleTouchMove = (e) => {
    if (pressed) {
      handleMouseMove(e.touches[0]);
    }
  };

  const handleTouchStart = (e) => {
    handlePress();
    setEvent(e.touches[0]);
  };

  const handlePress = () => {
    setSliderRect(slider.current.getBoundingClientRect());
    setPressed(true);
  };

  useEffect(() => {
    const { clientX } = event;
    const { disabled } = props;
    const { width, left, right } = sliderRect;

    if (!clientX || disabled) {
      return;
    }

    let position;
    if (clientX < left) {
      position = 0;
    } else if (clientX > right) {
      position = right - left;
    } else {
      position = clientX - left;
    }
    const currentStep = Math.round((position / width) * range.maxStep);
    const value = range.getValueForStep(currentStep);

    if (value || currentStep) {
      setValue(value);
      setCurrentStep(currentStep);
    }
  }, [event]);

  const { id, name, disabled, className } = props;

  const offset = (currentStep / range.maxStep) * 100;
  const offsetStyle = { left: `${offset}%` };

  return (
    <div
      className={`StepRangeSlider ${className}`}
      onMouseDown={handleMouseDown}
      ref={slider}
    >
      <div className="StepRangeSlider__track" />
      <div
        className="StepRangeSlider__handle"
        onTouchStart={handleTouchStart}
        onMouseDown={handleMouseDown}
        style={offsetStyle}
      >
        <div
          className="StepRangeSlider__thumb"
          aria-valuemin={range.minValue}
          aria-valuemax={range.maxValue}
          aria-valuenow={value}
          role="slider"
          onTouchStart={() => setPressed(true)}
          onTouchEnd={() => setPressed(false)}
        />
        <div className="StepRangeSlider__tooltip">{value}</div>
      </div>
      <input type="hidden" id={id} name={name} disabled={disabled} />
    </div>
  );
}
