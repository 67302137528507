import { ChevronDownIcon } from "@heroicons/react/solid";
import {
  Accordion as ReachAccordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from "@reach/accordion";
import { useState } from "react";

function Accordion({ items }: any) {
  const [indices, setIndices] = useState([]);

  function toggleItem(toggledIndex) {
    if (indices.includes(toggledIndex)) {
      setIndices(
        indices.filter((currentIndex) => currentIndex !== toggledIndex)
      );
    } else {
      setIndices([toggledIndex]);
    }
  }

  return (
    <ReachAccordion
      className="grid sm:grid-cols-2 gap-x-8 gap-y-5"
      index={indices}
      onChange={toggleItem}
    >
      {items.map((item: { label: string; text: string }, idx: number) => (
        <div key={idx} className="w-full relative">
          <AccordionItem>
            <AccordionButton
              className={`${
                idx === indices[0] ? "shadow rounded-t-lg" : "rounded-lg"
              } flex justify-between w-full p-4 text-md font-semibold text-left bg-gray-100 hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75`}
            >
              <span>{item.label}</span>
              <ChevronDownIcon
                className={`${
                  idx === indices[0] ? "transform rotate-180" : ""
                } w-5 h-5 flex-shrink-0`}
              />
            </AccordionButton>
            <AccordionPanel
              className={`${
                idx === indices[0] ? "w-full shadow rounded-b-lg" : null
              } px-4 py-3 text-sm bg-gray-100`}
            >
              {item.text}
            </AccordionPanel>
          </AccordionItem>
        </div>
      ))}
    </ReachAccordion>
  );
}
export default Accordion;
