import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { en } from "i18n/en";
import { ua } from "i18n/ua";
import { ru } from "i18n/ru";
import { es } from "i18n/es";
import { de } from "i18n/de";
import { cs } from "i18n/cs";
import { pt } from "i18n/pt";
import { hi } from "i18n/hi";
import { fr } from "i18n/fr";
import { it } from "i18n/it";
import { pl } from "i18n/pl";

const resources = {
  en: {
    translation: en
  },
  ua: {
    translation: ua
  },
  ru: {
    translation: ru
  },
  es: {
    translation: es
  },
  de: {
    translation: de
  },
  cs: {
    translation: cs
  },
  pt: {
    translation: pt
  },
  hi: {
    translation: hi
  },
  fr: {
    translation: fr
  },
  it: {
    translation: it
  },
  pl: {
    translation: pl
  }
};


i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
