import { BusinessPage } from "screens/server/BusinessPage";
import "i18n";

export interface IAuth0User {
  email: string;
  id: string;
}

export interface ILoggedUser {
  isLoggedIn: boolean;
  avatar: string;
  isExpert: boolean;
  expertAvatar: string;
  fullName?: string;
}

const Home = ({ user, acceptLanguageHeader, lang, subscriptionOffer }) => {
  return (
    <BusinessPage
      user={user}
      acceptLanguageHeader={acceptLanguageHeader}
      lang={lang}
      subscriptionOffer={subscriptionOffer}
    />
  );
};

export const getServerSideProps = async (context) => {
  const { query, req } = context;
  const cookies = req.cookies;
  const acceptLanguageHeader = req.headers["accept-language"];
  const lang = cookies?.language || null;
  const r = query.r || null;

  const user = await fetch(
    process.env.NEXT_PUBLIC_REST_URL + "/users/v1/users/me",
    {
      headers: {
        cookie: `access_token=${cookies?.access_token}; refresh_token=${cookies?.refresh_token}`,
      },
    }
  ).then((res) => res.json());

  let url = `/offers/v1/subscription-offer${!r ? "?currency=USD" : ""}${
    r
      ? `?r=${r}`
      : user?.expert?.referralCode
      ? `&r=${user?.expert?.referralCode}`
      : ""
  }`;

  const subscriptionOffer = await fetch(
    process.env.NEXT_PUBLIC_REST_URL + url
  ).then((res) => res.json());

  if (cookies.path) {
    return {
      redirect: {
        destination: `/sign-up${
          r
            ? `?r=${r}`
            : user?.expert?.referralCode
            ? `&r=${user?.expert?.referralCode}`
            : ""
        }`,
        permanent: false,
      },
    };
  }

  if (user?.id) {
    return {
      props: {
        user,
        acceptLanguageHeader,
        subscriptionOffer,
      },
    };
  }

  return {
    props: { user: null, acceptLanguageHeader, lang, subscriptionOffer },
  };
};

export default Home;
