import Image from "next/image";
import { translate } from "utils";

function Banner({
  onClick = () => {},
  title,
  backgroundColor = "bg-indigo-600",
  avatar = null,
  userAvatar,
}: {
  title?: string;
  backgroundColor?: string;
  iconColor?: string;
  onClick(): void;
  avatar?: string;
  userAvatar?: string;
}) {
  return (
    <div className={`cursor-pointer ${backgroundColor}`} onClick={onClick}>
      <div className="mx-auto py-3 lg:py-3.5 px-4 xl:px-8">
        <div className="flex gap-4 items-center justify-between lg:justify-start">
          {avatar ? (
            <img
              src={avatar}
              className="w-8 h-8 lg:w-9 lg:h-9 rounded-full bg-avatar-gradient shrink-0"
            />
          ) : (
            <img
              src="/img/avatar-profile.svg"
              alt="avatar"
              className="w-8 h-8 lg:w-9 lg:h-9 rounded-full bg-avatar-gradient shrink-0"
            />
          )}

          {userAvatar && (
            <img
              src={userAvatar}
              className="w-8 h-8 lg:w-9 lg:h-9 rounded-full bg-avatar-gradient shrink-0"
            />
          )}

          <p className="text-xs lg:text-lg text-center lg:text-start line-clamp-2 lg:line-clamp-none font-medium text-white break-all xxs:break-normal xxs:break-words">
            <span>{translate("yourSession")} </span>
            <span className="underline underline-offset-3 lg:underline-offset-4">
              {title}
            </span>
            <span> {translate("runningNow")}</span>
          </p>
          <div className="shrink-0 lg:ml-auto flex items-center gap-2 font-medium text-sm text-white py-[7px] px-2 lg:px-5 border outline-white rounded-lg">
            <Image src="/img/bell.gif" width={16} height={16} alt="" />
            <span className="hidden lg:inline">{translate("goToSession")}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
